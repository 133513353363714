.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.containerLists {
  max-width: 600px;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}
.content {
  height: calc(100vh - 166px);
  overflow-y: scroll;
  padding-top: 100px;
  background: #bd0015;
}
.header {
  height: 117px;
  background: #bd0015;
  display: flex;
  align-items: center;
  justify-content: center;
  &__top {
    height: 25px;
    background: #002b4c;
    opacity: 1;
  }
  img {
    width: 200px;
  }
  &__nav {
    background: #bd0015;
  }
}

.footer {
  background: #002b4c;
  opacity: 1;
  color: #ffff;
  text-align: center;
  padding: 15px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0px;
}


.content {
  
}
