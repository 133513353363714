.SwitchCheckbox {
  margin-top: -5px;
  cursor: pointer;
  width: 50px;
  input:not(old) {
    height: 0;
    width: 0;
    display: none;
    & + label {
      position: relative;
      display: flex;
      margin: 0.6em 0;
      align-items: center;
      color: #9e9e9e;
      transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1em;
        width: 1em;
        height: 1em;
        background: transparent;
        border: 2px solid #9e9e9e;
        border-radius: 2px;
        cursor: pointer;
        transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
      }
    }
    &:checked + label {
      & > ins {
        height: 100%;
      }
      & > span {
        border: 0.5em solid #ffeb3b;
        &:before {
          content: "";
          position: absolute;
          top: 0.6em;
          left: 0.2em;
          border-right: 3px solid transparent;
          border-bottom: 3px solid transparent;
          transform: rotate(45deg);
          transform-origin: 0% 100%;
          animation: SwitchCheckbox 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
        }
      }
    }
  }
  label {
    width: 40px;
    height: 20px;
    border-radius: 25px;
    background-color: #b3b3b3;
    z-index: 1;
    cursor: pointer;
    div {
      height: 24px;
      width: 24px;
      margin-left: -2px;
      border-radius: 25px;
      position: absolute;
      background: #fff;
      z-index: 2;
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease-in;
    }
    &:before {
      width: 40px;
      height: 20px;
      border-radius: 25px;
      background-color: #fff;
      position: absolute;
      z-index: 1;
      content: "";
      transition: all 0.35s linear;
      transform: scale(0);
    }
  }
  input:checked ~ label {
    transform: scale(1);

    div {
      transition: all 0.3s linear;
      transform: translateX(22px);
    }
    &:before {
      transition: all 0.3s linear;
      transform: scaleX(1);
      left: 0;
      background: #002b4c;
    }
  }
}

@keyframes SwitchCheckbox {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #212121;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
