.loading {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: #002b4c;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 20%;
        animation: animateImg 5s 0s infinite;
    }
}

@keyframes animateImg {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
  }