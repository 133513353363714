@font-face {
  font-family: LeagueGothicRegular;
  src: url('fonts/League_Gothic-webfont.ttf');
font-weight: 400;
}
@font-face {
  font-family: LeagueGothicRegular;
  src: url('fonts/League_Gothic-webfont.woff');
font-weight: 400;
}
*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased !important;
}
body, input, button {
  color: #222;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}
button {
  cursor: pointer;
}
.btn-link {
  width: auto!important;
  padding: 0px 0px;
  text-decoration: none;;
  color: #bd0015;
  
  &:hover{
    color: #2C5473;
    text-decoration: none;;
  }
  &:visited{
    color: #bd0015;
    text-decoration: none;;
  }
}