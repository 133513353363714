.nest {
  display: block;
  height: 50px;
  width: 50px;
  border: 2px solid transparent;
  border-top-color: #082b4c;
  border-radius: 50%;
  -webkit-animation: spin11 2s linear infinite;
  animation: spin11 2s linear infinite;
  &::before {
    content: "";
    position: absolute;
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
    border: 2px solid transparent;
    border-radius: 50%;
    border-top-color: #082b4c;
    -webkit-animation: spin11 3s linear infinite;
    animation: spin11 3s linear infinite;
  }
  &::after {
    content: "";
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border: 2px solid transparent;
    border-radius: 50%;
    border-top-color: #bd2b26;
    -webkit-animation: spin11 1.5s linear infinite;
    animation: spin11 1.5s linear infinite;
  }
}

@-webkit-keyframes spin11 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin11 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
